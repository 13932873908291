<template>
  <p v-if="isSectionHeading" class="ContentProseP">
    <slot/>
  </p>
  <p v-else>
    <slot/>
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  class: {
    type: String,
    default: '',
  }
})

const isSectionHeading = computed<boolean>(() => {
  return props.class === 'section-heading'
})
</script>

<style lang="scss">
.ContentProseP {
  @include type-section(m);
  line-height: var(--line-height-tight);
  color: var(--text-color-secondary);
  margin-bottom: var(--spacing-2x);
}
</style>
